import React from 'react'
import NetlifyForm from 'react-netlify-form'
import styled from 'styled-components'
import { map } from 'styled-components-breakpoint';

import { VIKING, HIPPIE_BLUE } from '../config/colors'

const SuccessBox = styled.div`
  background-color: ${VIKING};
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Karla", sans-serif !important;
  padding: 5px 10px;
  text-align: center;
  font-style: oblique;
  margin-top: 20px;
  color: #ffffff;
`

const ErrorBox = styled.div`
  background-color: 	#bb2124;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: "Karla", sans-serif !important;
  padding: 5px 10px;
  text-align: center;
  font-style: oblique;
  margin-top: 20px;
  color: #ffffff;
`

const FormArticle = styled.article`
  ${map({xs: '100%', lg: '700px'}, width => `width: ${width};`)}
`
const LabelForm = styled.label`
  font-family: "Karla", sans-serif !important;
`

const SubmitButtonForm = styled.input`
  font-family: "Karla", sans-serif !important;
`

const TitleForm = styled.h3`
  text-align: center;
  font-family: "Lora", serif !important;
  font-size: 30px;
  font-weight: 400;
  margin: 0;
`

const SubTitleForm = styled.p`
  text-align: center;
  font-family: "Lora", serif !important;
  font-size: 20px;
  font-weight: 400;
  margin: 5px 0 0 0;
`

const MessageForm = styled.p`
  text-align: center;
  font-family: "Lora", serif !important;
  font-size: 20px;
  font-weight: 400;
  margin: 20px 0 0 0;
  color: ${HIPPIE_BLUE};
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ContactForm = ({
  formTitle = '',
  formSubtitle = '',
  formMessage = ''
}) => {
  return (
    <NetlifyForm name='Contact Form'>
      {({ loading, error, success }) => (
        <FormContainer>
          <TitleForm>{formTitle}</TitleForm>
          <SubTitleForm>{formSubtitle}</SubTitleForm>
          <MessageForm>{formMessage}</MessageForm>
          <FormArticle className="pa4 black-80">
            <div className="measure center">
              <fieldset className="ba b--transparent ph0 mh0">
                <div className="mt3">
                  <LabelForm className="db fw4 lh-copy f6" htmlFor="fullname">Nombre*</LabelForm>
                  <input
                    id="fullname"
                    name="fullname"
                    type="text"
                    className="b pa2 input-reset ba bg-transparent w-100 measure"
                    required
                  />
                </div>
                <div className="mt3">
                  <LabelForm className="db fw4 lh-copy f6" htmlFor="email">Correo Electrónico*</LabelForm>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="b pa2 input-reset ba bg-transparent w-100 measure"
                    required
                  />
                </div>
                <div className="mt3">
                  <LabelForm className="db fw4 lh-copy f6" htmlFor="message">Mensaje*</LabelForm>
                  <textarea
                    id="message"
                    name="message"
                    type="text"
                    className="b pa2 input-reset ba bg-transparent w-100 measure"
                    required
                  />
                </div>
              </fieldset>
              <div className="mt3">
                <SubmitButtonForm
                  className="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6"
                  type="submit"
                  value="Enviar"
                />
              </div>
              {success && (
                <SuccessBox>Mensaje enviado con éxito</SuccessBox>
              )}
              {error && (
                <ErrorBox>Algo salió mal, intenta de nuevo.</ErrorBox>
              )}
            </div>
          </FormArticle>
        </FormContainer>
      )}
    </NetlifyForm>
  )
}

export default ContactForm