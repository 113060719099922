import React from "react"
import Img from 'gatsby-image';
import styled from 'styled-components'
import { map } from 'styled-components-breakpoint';
import 'tachyons';

import '../../common/styles/custom.tachyons.css'

const DivContent = styled.div`
  font-family: "Karla", sans-serif !important;
  text-align: center;
`

const CoverContent = styled.div`
  position: relative;
`

const BlockMessageCover = styled.div`
  position: absolute;
  right: 20%;
  ${map({xs: '250px', lg: '500px'}, width => `width: ${width};`)}
  ${map({xs: '10%', md: '15%', lg: '20%'}, right => `right: ${right};`)}
  top: 10%;
  text-align: center;
  font-family: "Lora", serif !important;
  ${map({xs: '20px', sm: '24px', md: '28px', lg: '36px'}, fontSize => `font-size: ${fontSize};`)}
  font-weight: 400;
  margin: 0;
  color: #FFFFFF;
`

export default ({
  image = '',
  imageDescription = '',
  title = '',
  description = '',
  anchor = '',
  id = '',
  coverMessage = '',
}) => (
  <div id={id}>
    <CoverContent>
      <Img
        className="w-100 vh-50 mw9 center"
        fluid={image}
        alt={imageDescription}
      />
      <BlockMessageCover dangerouslySetInnerHTML={{__html: coverMessage }} />
    </CoverContent>
    <div className="h-auto bg-near-white mw9 w-100 flex flex-column items-center justify-center pv5 ph2 center">
      <span className="fw1 display dark-gray db tc w-100 mw7 f3 f2-ns" dangerouslySetInnerHTML={{__html: title}} />
      <DivContent className="serif mw9 tc f5 dn dib-l mb4 db" dangerouslySetInnerHTML={{__html: description}} />
    </div>
  </div>
)
