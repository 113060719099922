import React from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components'
import 'tachyons'

const DivContent = styled.div`
  font-family: "Karla", sans-serif !important;
  text-align: left;
  p {
    margin: 0;
  }
`

const PictureCredits = styled.p`
  font-family: "Karla", sans-serif !important;
`


export default ({
  bioTitle = '',
  bioContent = '',
  bioFotografia = '',
  id = '',
  bioFotoCreditos = ''
}) => {
  return (
    <div id={id} className="pv5 pa2 flex flex-wrap mw9 center justify-around items-center">
      <div className="w-100 pa2 mw6 mv4">
        <Img fluid={bioFotografia.localFile.childImageSharp.fluid} alt={bioFotografia.alt_text} className="w-100 mw6" />
        <PictureCredits>{bioFotoCreditos}</PictureCredits>
      </div>
      <div className="w-100 pa2 mw6 mv4">
        <span className="db f2 display dark-gray">{bioTitle}</span>
        <DivContent className="lh-copy f5 serif mt4" dangerouslySetInnerHTML={{__html: bioContent}} />
      </div>
    </div>
  )
}
