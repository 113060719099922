import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image';

const MessageBox = styled.div`
  font-family: "Karla", sans-serif !important;
  width: 100%;
  padding: 0 80px;
  text-align: center;
  font-style: oblique;
`

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:focus, &:active, &:hover, &:visited {
    outline: none !important;
  }
  padding: 40px 0;
`

const TestimonialImage = styled(Img)`
  width: 100px;
  border-radius: 50px 20px;
`

const TestimonialAuthorName = styled.p`
  font-family: "Karla", sans-serif !important;
`

export default function TestimonialCard({
  testimonial,
  testimonialAuthor,
  testimonialImage
}) {
  return(
    <TestimonialContainer>
      <MessageBox dangerouslySetInnerHTML={{__html: testimonial}} />
      <TestimonialImage
        fluid={testimonialImage.localFile.childImageSharp.fluid}
        alt={testimonialImage.alt_text}
      />
      <TestimonialAuthorName>{testimonialAuthor}</TestimonialAuthorName>
    </TestimonialContainer>
  )
}