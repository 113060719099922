import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import Img from 'gatsby-image';
import Lightbox from 'react-image-lightbox';
import { map } from 'styled-components-breakpoint';

import Layout from '../common/layouts';
import Hero from '../homepage/components/hero';
// import Card from '../homepage/components/card';
// import About from '../homepage/components/about';
import Bio from '../homepage/components/bio';
import Seo from '../common/seo';
import TestimonialCard from '../common/components/testimonial-card'
import ContactForm from '../contact-form'

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';

const ContainerCarouselImage = styled.div`
  height: 450px;
  width: 300px;
  &:hover {
    cursor: pointer;
  }
`
const CarouselImage = styled(Img)`
  width: 100%;
  height: 100%;
`

const StyledSlider = styled(Slider)`
  width: 100%;
`

const DivContent = styled.div`
  font-family: "Karla", sans-serif !important;
  text-align: center;
  ${map({xs: '10px', lg: 'auto'}, padding => `padding: 0 ${padding};`)}
  p {
    margin: 0;
  }
`

const TitleTestimonies = styled.h3`
  text-align: center;
  font-family: "Lora", serif !important;
  font-size: 30px;
  font-weight: 400;
  margin: 0;
`

export default ({ data, pageContext }) => {
  const [slideNumber, setSlideNumber] = useState(1)
  const [breakpoint, setBreakpoint] = useState('xs')
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  
  const reportWindowSize = () => {
    const breakpoints = {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    }

    Object.keys(breakpoints).map(k => {
      if (document.documentElement.clientWidth > breakpoints[k]) setBreakpoint(k)
    })
  }

  useEffect(() => {
    if (window) window.addEventListener('resize', reportWindowSize);
    reportWindowSize()
  }, [])

  useEffect(() => {
    const slidesByBreakpoint = {
      xs: 1,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 3,
    }

    setSlideNumber(slidesByBreakpoint[breakpoint])
  }, [breakpoint])

  return (
    <Layout>
      <Seo />
      <Hero
        id={pageContext.acfPage.idHomeTopScrollTo || ''}
        title={pageContext.acfPage.mensajePrincipalTitulo}
        image={pageContext.acfPage.imagenCover.localFile.childImageSharp.fluid}
        imageDescription={pageContext.acfPage.imagenCover.altText}
        coverMessage={pageContext.acfPage.mensajeDeImagenDeCover}
        description={pageContext.acfPage.mensajePrincipalParrafo}
        to={'/'}
      />
      <div id={pageContext.acfPage.idGalleriaScrollTo || ''} className="flex flex-wrap center mw9 justify-around pb3">
        <StyledSlider 
          {...
            {
              draggable: false,
              arrows: false,
              dots: true,
              infinite: true,
              autoplay: true,
              autoplaySpeed: 4000,
              speed: 1000,
              slidesToShow: slideNumber,
              slidesToScroll: slideNumber
            }
          }>
          {pageContext.acfPage.galeria.map(({imagenGaleria}, idx) => (
            <ContainerCarouselImage
              key={imagenGaleria.id}
              onClick={() => {
                setPhotoIndex(idx)
                setIsOpen(true)
              }}
            >
              <CarouselImage
                fluid={imagenGaleria.localFile.childImageSharp.fluid}
                alt={imagenGaleria.altText}
              />
            </ContainerCarouselImage>
          ))}
        </StyledSlider>
      </div>
      <div className="flex flex-wrap center mw9 justify-around pb6 pt5">
        <DivContent dangerouslySetInnerHTML={{__html: pageContext.acfPage.textoDebajoDeGaleria}} />
      </div>
      <div className="flex flex-wrap center mw9 justify-around pb3">
        <TitleTestimonies id={pageContext.acfPage.idTestimoniosScrollTo || ''}>{pageContext.acfPage.tituloDeTestimonios}</TitleTestimonies>
        <StyledSlider 
          {...
            {
              arrows: false,
              dots: true,
              infinite: true,
              autoplay: true,
              autoplaySpeed: 4000,
              speed: 1000,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }>
          {pageContext.acfPage.testimonialCarouselSlider.map(({
            testimonial,
            testimonialAuthor,
            testimonialImage
          }) => (
            <TestimonialCard
              key={testimonialImage.id}
              testimonial={testimonial}
              testimonialImage={testimonialImage}
              testimonialAuthor={testimonialAuthor}
            />
          ))}
        </StyledSlider>
      </div>
      <div id={pageContext.acfPage.idContactoScrollTo || ''} className="flex flex-wrap center mw9 justify-around pb6 pt5">
        <ContactForm formTitle={pageContext.acfPage.formTitle} formSubtitle={pageContext.acfPage.form_subtitle} formMessage={pageContext.acfPage.formMessage} />
      </div>
      {/* <About /> */}
      <Bio
        id={pageContext.acfPage.idSobreNosotrosScrollTo || ''}
        bioContent={pageContext.acfPage.bioContent}
        bioFotografia={pageContext.acfPage.bioFotografia}
        bioTitle={pageContext.acfPage.tituloBiografia}
        bioFotoCreditos={pageContext.acfPage.bioFotoCredito}
      />
      {isOpen && (
        <Lightbox
          mainSrc={pageContext.acfPage.galeria[photoIndex].imagenGaleria.sourceUrl}
          nextSrc={pageContext.acfPage.galeria[(photoIndex + 1) % pageContext.acfPage.galeria.length].imagenGaleria.sourceUrl}
          prevSrc={pageContext.acfPage.galeria[(photoIndex + pageContext.acfPage.galeria.length - 1) % pageContext.acfPage.galeria.length].imagenGaleria.sourceUrl}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + pageContext.acfPage.galeria.length - 1) % pageContext.acfPage.galeria.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % pageContext.acfPage.galeria.length)
          }
        />
      )}
    </Layout>
  )
}
